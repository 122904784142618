<template>
  <div class="row justify-content-center px-8 px-lg-10">
    <div class="col-xl-12 col-xxl-7 justify-content-center">
      <!--begin::Form Wizard-->
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form class="form">
        <!--begin::Step 1-->
        <div data-wizard-type="step-content" data-wizard-state="current">
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label"
                  >{{ $t('commons.first_name') }}</label
                >
                <validation-provider rules="required" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <input
                    class="form-control"
                    :class="classes"
                    :name="$t('commons.first_name')"
                    type="text"
                    v-model="employees.name"
                  />
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label"
                  >{{ $t('commons.last_name') }}</label
                >
                <validation-provider rules="required" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <input
                    class="form-control"
                    :class="classes"
                    :name="$t('commons.last_name')"
                    type="text"
                    v-model="employees.surname"
                  />
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label"
                  >{{ $t('commons.mobile_phone') }}</label
                >
                <validation-provider rules="required" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <div class="input-group" :class="classes">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="la la-phone"></i
                      ></span>
                    </div>
                    <input
                      type="number"
                      value=""
                      :name="$t('commons.mobile_phone')"
                      id="phoneValid"
                      minlength="10"
                      :placeholder="$t('commons.phone_example', {value: ' - 533xxxxxxx'})"
                      aria-describedby="basic-addon1"
                      class="form-control"
                      :class="classes"
                      v-model="employees.phone"
                    />
                  <span class="error__message">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label"
                  >{{ $t('commons.e_mail') }}</label
                >
                <validation-provider rules="required|email" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <div class="input-group" :class="classes">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="la la-at"></i
                      ></span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      :class="classes"
                      :name="$t('commons.e_mail')"
                      :placeholder="$t('commons.e_mail')"
                      v-model="employees.email"
                    />
                  <span class="error__message">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.password') }}</label>
                <validation-provider rules="" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <input
                    class="form-control"
                    :class="classes"
                    :name="$t('commons.password')"
                    type="text"
                    v-model="employees.password"
                  />
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label"
                  >{{ $t('commons.department') }}</label
                >
                <validation-provider rules="required" :name="$t('commons.department')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <multiselect
                      v-model="selectedDepartment"
                      :options="departments"
                      :searchable="false"
                      :allow-empty="false"
                      open-direction="bottom"
                      @input="onChangeDepartment"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      track-by='label'
                      label='label'
                      :placeholder="$t('commons.choosen_department')"
                      :class="classes"
                  >
                    <template slot='singleLabel' slot-scope='{ option }'>{{ $t(`service.${option.label}`) }}</template>
                    <template slot='option' slot-scope='{ option }'>{{ $t(`service.${option.label}`) }}</template>
                    <template slot='noOptions'>{{ 'List is empty' }}</template>
                  </multiselect>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.user_id') }}</label>
                <div class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <input
                    class="form-control"
                    :name="$t('commons.user_id')"
                    type="number"
                    v-model="disabledInputUserId"
                    disabled
                  />
                </div>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label"
                  >{{ $t('employees.is_responsible') }}</label
                >
                <validation-provider rules="required" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <div class="radio-inline col-form-label">
                    <label class="radio" for="no">
                      <input
                        type="radio"
                        id="no"
                        :name="$t('commons.responsible')"
                        value="0"
                        v-model="employees.is_responsible"
                        @input="isResponsibleNo"
                      />
                      <span class="form-control p-0" :class="!employees.is_responsible ? classes : null"></span>{{ $t('commons.no') }}
                    </label>
                    <label class="radio" for="yes">
                      <input
                        type="radio"
                        id="yes"
                        :name="$t('commons.responsible')"
                        value="1"
                        v-model="employees.is_responsible"
                      />
                      <span class="form-control p-0" :class="!employees.is_responsible ? classes : null"></span>{{ $t('commons.yes') }}
                    </label>
                  </div>
                  <span>{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>

              <div class="form-group row" v-if="isResponsible">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('employees.institutions_to_be_responsible_with') }}</label>
                <div class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <multiselect
                    v-model="selectedInstitutions"
                    :options="institutions"
                    :searchable="false"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    open-direction="bottom"
                    deselect-label=''
                    select-label=''
                    selected-label=''
                    track-by='name'
                    label='name'
                    :placeholder="$t('employees.institutions_to_be_responsible_with')"
                  >
                    <template slot='singleLabel' slot-scope='{ option }'>{{ option.name }}</template>
                    <template slot='option' slot-scope='{ option }'>{{ option.name }}</template>
                    <template slot='noOptions'>{{ 'List is empty' }}</template>
                  </multiselect>
                </div>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.status') }}</label>
                <validation-provider rules="required" :name="$t('commons.status')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <multiselect
                      v-model="selectedStatus"
                      :options="statuses"
                      :searchable="false"
                      :allow-empty="false"
                      open-direction="bottom"
                      @input="onChangeStatus"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      track-by='label'
                      label='label'
                      :placeholder="$t('commons.choosen_status')"
                      :class="classes"
                  >
                    <template slot='singleLabel' slot-scope='{ option }'>{{ $t(`service.${option.label}`) }}</template>
                    <template slot='option' slot-scope='{ option }'>{{ $t(`service.${option.label}`) }}</template>
                    <template slot='noOptions'>{{ 'List is empty' }}</template>
                  </multiselect>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.will_notification_be_sent') }}</label>
                <div class="col-lg-9 col-xl-9">
                  <span class="switch switch-sm switch-icon" :class="{'switch-primary': `${employees.email_send}` === '1'}">
                    <label class="row align-items-center">
                      <input type="checkbox" :checked="`${employees.email_send}` === '1'" :false-value="0" :true-value="1" v-model="employees.email_send" />
                      <span></span>{{ employees.email_send ? $t('commons.yes') : $t('commons.no') }}
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--begin::Actions-->
        <div class="d-flex justify-content-end border-top mt-5 pt-10">
          <form-submit-btn
            :btnClass="'btn btn-success font-weight-bolder text-uppercase px-9 py-4'"
            :isSubmitting="isSubmitting"
            @click="handleSubmit(createEmployees)"
            :text="$t('commons.submit')"
          />
        </div>
        <!--end::Actions-->
      </form>
      </ValidationObserver>
      <!--end::Form Wizard-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { Skeleton } from 'vue-loading-skeleton';

export default {
  name: "NewEmployees",
  data() {
    return {
      isSubmitting: false,
      selectedDepartment: null,
      selectedStatus: null,
      selectedInstitutions: null,
      employees: {
        name: "",
        surname: "",
        phone: "",
        email: "",
        password: "",
        department_id: "",
        type: "",
        status: "",
        user_id: "",
        is_responsible: "0",
        institutionIds: [],
        email_send: false
      },
      disabledInputUserId: ''
    };
  },
  components: {
    Multiselect,
    Skeleton
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.employees.isLoading,
      departments: (state) => state.employees.employees_new_get.departments,
      statuses: (state) => state.employees.employees_new_get.statuses,
      institutions: (state) => state.employees.employees_new_get.institutions,
      employees_data: (state) => state.employees.employees_new_get
    }),
    isResponsible() {
      return this.employees.is_responsible?.toString() === '1'
    }
  },
  methods: {
    ...mapActions("employees", ["GET_NEW_EMPLOYESS", "POST_EMPLOYESS"]),
    createEmployees() {
      if(this.isResponsible && this.selectedInstitutions.length > 0) {
        this.employees.institutionIds = this.selectedInstitutions.map(val => val.id)
      }
      this.isSubmitting = true
      this.POST_EMPLOYESS(this.employees)
        .then(() => {
          this.$refs.form.reset();
          this.$router.push({ name: "Employees" });
        })
        .catch(() => {})
        .finally(() => {
          this.isSubmitting = false
        })
    },
    resetForm() {
      this.employees = {
        name: "",
        surname: "",
        phone: "",
        email: "",
        password: "",
        department_id: "",
        type: "",
        status: "",
        user_id: "",
        is_responsible: "0",
        institutionIds: []
      }
      this.selectedDepartment = null;
      this.selectedStatus = null;
      this.selectedInstitutions = null;
    },
    onChangeDepartment() {
      this.employees.department_id = this.selectedDepartment.value
    },
    onChangeStatus() {
      this.employees.status = this.selectedStatus.value
    },
    isResponsibleNo() {
      this.employees.institutionIds = []
    },
  },
  created() {
    this.GET_NEW_EMPLOYESS().then(() => {
      this.employees.user_id = this.employees_data?.user_id || ""
      this.disabledInputUserId = this.employees_data?.user_id || ""
    })
  }
};
</script>
